type ENV = 'dev' | 'test' | 'prod';

const Env: ENV = 'prod';
// api
const api = {
  dev: 'https://api.dev.adcloud.palmax.io/',
  test: 'https://api.dev.adcloud.palmax.io/',
  prod: 'https://api-adcloud.palmax.com'
};

// 用于项目间跳转
export const appConfig = {
  api: api[Env]
};

const hosts = {
  dev: {
    adcloud: 'https://dev.adcloud.palmax.io'
  },
  test: {
    adcloud: 'https://dev.adcloud.palmax.io'
  },
  prod: {
    adcloud: 'https://adcloud.palmax.com'
  }
};

export const hostConfig = hosts[Env];

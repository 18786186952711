import axios from 'axios';
import { appConfig, hostConfig } from 'config/app.config';
import Message from 'components/common/Message';
import { forwardTo } from './tool';
// import { forwardTo } from './tool';
const baseURL = process.env.CREATIVE_URL || appConfig.api;
console.log(baseURL, 'baseURL');

const request = axios.create({
  baseURL,
  headers: { 'Content-Type': 'application/json' },
  transformRequest: [
    data => {
      if (data instanceof FormData) {
        return data;
      }
      return JSON.stringify(data);
    }
  ],
  withCredentials: true
});

request.interceptors.request.use(
  config => {
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

request.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error && error.response) {
      const status = error.response.status;
      const data = error.response.data;
      const redirectToLogin = () => {
        // 这里打开错误会报两次错误信息
        const errorMsg = (data && data.message) || 'Logon failure';
        Message.error(errorMsg);
        forwardTo(`${hostConfig.adcloud}/#/login`);
      };
      console.error(error.response);

      if (status === 500) {
        // 500 返回html的情况
        const errorMsg = data && data.message ? data.message : 'Internal Server Error';
        Message.error(errorMsg);
        return Promise.reject(error.response);
      }

      if (status === 404) {
        Message.error('Request not found');
        return Promise.reject(error.response);
      }

      if ([401, 401.1, 332].includes(status)) {
        // cookie失效，登录失效，返回登录界面
        redirectToLogin();
        return Promise.reject(error.response);
      }

      // 其它错误
      const errorMsg = (data && data.message) || data.error || 'error';
      Message.error(errorMsg);
      return Promise.reject(error.response);
    }

    return Promise.reject(error.response);
  }
);

export const http = request;

import { observable, action, runInAction, computed } from 'mobx';
import { addCampaignApi } from 'services/twitter/addCampaign.service';
import * as CampaignTypes from 'interface/twitter/compaign.interface';
import { cloneDeep } from 'lodash';
import Message from 'components/common/Message';
import { http } from 'utils/http';
import { Follower } from 'interface/twitter/compaign.interface';

export const placementTypeList = [
  { type: '', name: '0', label: 'Profiles', value: 'TWITTER_PROFILE' },
  { type: '', name: '1', label: 'Search results', value: 'TWITTER_SEARCH' },
  { type: '', name: '2', label: 'User’s timelines', value: 'TWITTER_TIMELINE' },
  {
    type: '',
    name: '3',
    label: 'Twitter Audience Platform',
    value: 'PUBLISHER_NETWORK'
  }
];

// placement只有产生固定的组合才具有投放效应，前端方可提交
// 固定组合如下
export const placementLanuchMap = {
  '012': ['ALL_ON_TWITTER'],
  '0123': ['ALL_ON_TWITTER', 'PUBLISHER_NETWORK'],
  '3': ['PUBLISHER_NETWORK'],
  '023': ['PUBLISHER_NETWORK', 'TWITTER_PROFILE', 'TWITTER_TIMELINE'],
  '123': ['PUBLISHER_NETWORK', 'TWITTER_SEARCH', 'TWITTER_TIMELINE'],
  '23': ['PUBLISHER_NETWORK', 'TWITTER_TIMELINE'],
  '02': ['TWITTER_PROFILE', 'TWITTER_TIMELINE'],
  '1': ['TWITTER_SEARCH'],
  '12': ['TWITTER_SEARCH', 'TWITTER_TIMELINE'],
  '2': ['TWITTER_TIMELINE']
};

export const withinPlacementValid = (names: string[]): string[] | undefined => {
  const key = names
    .slice()
    .sort()
    .join('');
  return placementLanuchMap[key];
};

// 将服务器返回的编辑数据反向转为['0', '1', '2', '3']的格式;
const handlePlacementFromData = (placement: string[]) => {
  let retkey = '';
  Object.keys(placementLanuchMap).forEach(key => {
    const mapValue = placementLanuchMap[key];
    if (placement.length === mapValue.length && placement.every((value: string) => mapValue.includes(value))) {
      retkey = key;
    }
  });
  return retkey.split('');
};

export enum ObjectiveListEnum {
  APP_INSTALL = 'APP_INSTALL',
  Conversions = 'Conversions',
  CatelogSales = 'CatelogSales'
}

interface ISelectLists {
  countries: CampaignTypes.Location[];
  interests: CampaignTypes.Interest;
  os_version: CampaignTypes.OsVersion[];
  platform: CampaignTypes.Platform[];
  language: CampaignTypes.Location[];
  device_model: CampaignTypes.DeviceModel[];
  carriers: CampaignTypes.Carry[];
}

// type OsType = 'ios' | 'android';

export class AddCampaignStore {
  @observable
  public objectiveType = ObjectiveListEnum.APP_INSTALL;

  @observable
  public lineItemApps = [];

  @observable
  public categories = [];

  @observable
  public commonSelects: ISelectLists = {
    countries: [],
    interests: {},
    os_version: [],
    platform: [],
    language: [],
    device_model: [],
    carriers: []
  };

  // @observable
  // public osVersionType: OsType = 'ios';

  // @computed
  // public get os_versionSelect() {
  //   const selects = {
  //     ios: this.commonSelects.os_version.filter(version => version.os_type === 'iOS'),
  //     android: this.commonSelects.os_version.filter(version => version.os_type === 'Android')
  //   };

  //   return selects[this.osVersionType];
  // }

  // 编辑时当前campaign
  @observable
  public currentCampaign: CampaignTypes.ICampaignFormData | null = null;
  private currentCampaignId: number | null;
  public follower_look_alikes_required = false;
  public keywords_required = false;
  public interests_required = false;

  @observable
  public audiences = {
    MOBILE: [],
    TAILORED_WEB: [],
    TAILORED_LISTS: [],
    FLEXIBLE: []
    // MOBILE: [],
    // TAILORED_WEB: [],
    // TAILORED_LISTS: [],
    // FLEXIBLE: []
  };

  @computed
  public get isEdit() {
    return !!this.currentCampaignId || this.currentCampaignId === 0;
  }

  // setter
  @action.bound
  public setObjectiveType(objectiveType: ObjectiveListEnum) {
    this.objectiveType = objectiveType;
  }
  @action.bound
  public setCurrentCampaign(campaign: CampaignTypes.ICampaignFormData | null) {
    this.currentCampaign = campaign;
  }

  // @action.bound
  // public setOsVersionType(osType: OsType) {
  //   this.osVersionType = osType;
  // }

  private handleApiCampaignData = (data: any) => {
    const {
      // --- programmatic
      status,
      programmatic_campaign_name,
      account_id,
      app_store_url,
      cpi_goal,
      // ---- campaign
      campaign_name,
      daily_budget,
      number_of_ad_group_limit,
      // ---- ad_group
      ad_group_name,
      bid_type,
      launch_bid,
      number_of_creative_limit,
      number_of_ads_limit, ///// 有问题
      // ---- target
      tailored_flexible_audiences,
      platforms,
      age,
      gender,
      os_version,
      language,
      device_model,
      carriers,
      location,
      keywords,
      interests,
      follower_look_alikes,
      audience_expansion,
      // ---- placement
      placement,
      ad_categories,
      website_domain
    } = data;

    return {
      programmatic: {
        status: status === 'active' ? '0' : '1',
        programmatic_campaign_name,
        account_id,
        app_store_url,
        cpi_goal: String(cpi_goal)
      },
      campaign: {
        campaign_name,
        daily_budget: String(daily_budget),
        number_of_ad_group_limit: String(number_of_ad_group_limit)
      },
      ad_group: {
        ad_group_name,
        bid_type,
        launch_bid: String(launch_bid),
        number_of_creative_limit: String(number_of_creative_limit || number_of_ads_limit)
      },
      target: {
        tailored_flexible_audiences,
        platforms,
        gender,
        os_version,
        language: language.map((i: any) => ({
          ...i,
          label: `${(i.targeting_value as string).toUpperCase()} - ${i.name}`
        })),
        age,
        device_model,
        carriers,
        location,
        // keywords: keywords.join(';'),
        keywords: keywords.map((v: string) => ({ label: v, value: v })),
        interests: interests.map((i: any) => ({ ...i, value: i.name, label: i.name })),
        follower_look_alikes: follower_look_alikes.map((v: CampaignTypes.Follower) => ({
          label: `${v.screen_name}`,
          value: v.id
        })),
        audience_expansion
      },
      placement: {
        placement: handlePlacementFromData(placement[0].placements),
        ad_categories,
        website_domain
      }
    };
  };

  // 详细campaing拉取
  @action.bound
  public editCampaign(id: number) {
    return addCampaignApi.getCampaing(String(id)).then(res => {
      runInAction(() => {
        this.currentCampaign = this.handleApiCampaignData(res.data);
        const { follower_look_alikes, interests, keywords } = res.data;
        if (follower_look_alikes && follower_look_alikes.length) {
          this.follower_look_alikes_required = true;
        }
        if (interests && interests.length) {
          this.interests_required = true;
        }
        if (keywords && keywords.length) {
          this.keywords_required = true;
        }
      });
      this.currentCampaignId = id;
      return res.data;
    });
  }

  // 获取target下拉值
  @action.bound
  public getCommonSelects() {
    const generatePro = (dataType: string) => addCampaignApi.getSelect(dataType);
    const keys = Object.keys(this.commonSelects);
    // 缓存接口
    if (keys.every(key => !!this.commonSelects[key].length)) {
      return;
    }
    Promise.all(keys.map(generatePro)).then(res => {
      runInAction(() => {
        keys.forEach((key, i) => {
          const ret = res && res[i] && res[i].data;
          if (ret) {
            this.commonSelects[key] = ret;
          }
        });
      });
    });
  }

  @action.bound
  public getLineItemApps(accountId: string) {
    return addCampaignApi.getLineItemApps(accountId).then(res => {
      runInAction(() => {
        this.lineItemApps = res.data;
      });
    });
  }

  @action.bound
  public getFollower_look_alikesList(querystring: string) {
    return addCampaignApi.getFollower_look_alikesList({ follower: querystring }).then(res => {
      return Promise.resolve(
        res.data.map((v: Follower) => ({
          label: `${v.name}, @${v.screen_name}`,
          value: v.id
        }))
      );
    });
  }

  @action.bound
  public getCategories() {
    if (this.categories.length) {
      return;
    }
    addCampaignApi.getCategories().then(res => {
      runInAction(() => {
        this.categories = res.data;
      });
    });
  }

  @action.bound
  public clear() {
    this.setCurrentCampaign(null);
    this.currentCampaignId = null;
    this.lineItemApps = [];
    this.follower_look_alikes_required = false;
  }

  private changeNumberToString(data: any, names: string[], handler?: Function) {
    names.forEach(name => {
      data[name] = +data[name];
    });

    handler && handler(data);
    return data;
  }
  // 除placement外其它数据
  private changeDataInner(data: any) {
    const funcMap: { [K: string]: (prop: string) => void } = {
      status: (prop: string) => {
        data[prop] = data[prop] === '0' ? 'active' : 'paused';
      },
      account_id: (prop: string) => {
        data[prop] = data[prop].accountId;
      },
      app_store_url: (prop: string) => {
        const { image_url, ...rest } = data[prop];
        data[prop] = rest;
      },
      carriers: (prop: string) => {
        // 如果为null传空数组，否则后端报错
        data[prop] = data[prop] || [];
      },
      keywords: (prop: string) => {
        data[prop] = data[prop] ? data[prop].map((v: { label: string }) => v.label) : [];
      },
      interests: (prop: string) => {
        data[prop] = data[prop] || [];
      },
      follower_look_alikes: (prop: string) => {
        data[prop] = data[prop]
          ? data[prop].map((v: { label: string; value: number }) => ({
              screen_name: v.label,
              id: v.value
            }))
          : [];
      },
      language: (prop: string) => {
        // 如果为null传空数组，否则后端报错
        data[prop] = data[prop] || [];
      },
      platforms: (prop: string) => {
        data[prop] = data[prop] || [];
      },
      audience_expansion: (prop: string) => {
        data[prop] = data[prop] || '';
      }
    };

    for (const prop in data) {
      if (funcMap[prop]) {
        funcMap[prop].call(null, prop);
      }
    }
  }

  private changePlacementInner(data: any) {
    data.placement = [
      {
        product_type: 'PROMOTED_TWEETS',
        placements: withinPlacementValid(data.placement)
      }
    ];
  }

  private generateSubmit(data: CampaignTypes.ICampaignFormData) {
    // 深拷贝，不改变原数据;
    data = cloneDeep(data);
    return {
      objective: this.objectiveType,
      ...this.changeNumberToString(data.programmatic, ['cpi_goal'], this.changeDataInner),
      ...this.changeNumberToString(data.campaign, ['daily_budget', 'number_of_ad_group_limit'], this.changeDataInner),
      ...this.changeNumberToString(data.ad_group, ['launch_bid', 'number_of_creative_limit'], this.changeDataInner),
      ...this.changeNumberToString(data.target, [], this.changeDataInner),
      ...this.changeNumberToString(data.placement, [], this.changePlacementInner)
      // target中暂时未用的字段，只在store中出现该次
      // tailored_flexible_audiences: 'tailored_flexible_audiences'
    };
  }

  @action.bound
  public async submit(data: CampaignTypes.ICampaignFormData) {
    // return this.currentCampaign
    //   ? addCampaignApi.update(this.currentCampaignId!, this.generateSubmit(data))
    //   : addCampaignApi.submit(this.generateSubmit(data));

    const res = await (this.currentCampaignId
      ? addCampaignApi.update(this.currentCampaignId, this.generateSubmit(data))
      : addCampaignApi.submit(this.generateSubmit(data)));

    Message.success(this.currentCampaignId ? 'Edit successfully' : 'Added successfully');
    return res;
  }

  @action.bound
  public copyCampaign(id: number) {
    return addCampaignApi.getCampaing(String(id)).then(res => {
      runInAction(() => {
        this.currentCampaign = this.handleApiCampaignData(res.data);
        const { follower_look_alikes, interests, keywords } = res.data;
        if (follower_look_alikes && follower_look_alikes.length) {
          this.follower_look_alikes_required = true;
        }
        if (interests && interests.length) {
          this.interests_required = true;
        }
        if (keywords && keywords.length) {
          this.keywords_required = true;
        }
      });
      return res.data;
    });
  }

  @action.bound
  public async fetchAudience(account_id: string) {
    try {
      const { data } = await http.get(`/api/creator_tw_api/twitter/data/${account_id}/audiences`);
      runInAction(() => {
        this.audiences = data.data;
      });
    } catch (error) {
      console.log(error);
    }
  }

  @observable
  public recommendKeywords: string[] = [];

  @observable
  public recommendFollowers: Follower[] = [];

  @action.bound
  public changeRecommendKeywords = (index: number) => {
    this.recommendKeywords.splice(index, 1);
  };

  @action.bound
  public changeRecommendFollowers = (index: number) => {
    this.recommendFollowers.splice(index, 1);
  };

  @action.bound
  public async fetchRecommendKeywords(accountId: string, keywords: any) {
    try {
      const { data } = await http.get(
        `api/creator_tw_api/twitter/${accountId}/recommendations/keyword?keyword=${encodeURIComponent(
          keywords[keywords.length - 1].value
        )}`
      );
      runInAction(() => {
        // 获取当前选择的 keywords 数组，用于去除接口返回的重复数据
        const keywordsValue = keywords.map((v: any) => v.value);
        this.recommendKeywords = data.data.filter((d: any) => !keywordsValue.includes(d));
      });
    } catch (error) {
      console.log(error);
    }
  }

  @action.bound
  public async fetchRecommendFollowers(accountId: string, followerId: any) {
    try {
      const { data } = await http.get(
        `api/creator_tw_api/twitter/${accountId}/recommendations/follower_look_alikes?follower_look_alikes=${encodeURIComponent(
          followerId[followerId.length - 1].value
        )}`
      );
      runInAction(() => {
        // 获取当前选择的 follower look alikes 数组，用于去除接口返回的重复数据
        const followerLookAlikesValue = followerId.map((v: any) => v.value);
        this.recommendFollowers = data.data.filter((d: any) => !followerLookAlikesValue.includes(d.id));
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export const addCampaignStore = new AddCampaignStore();

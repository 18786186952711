import { http } from "utils/http";
import { GET, PUT, IHtttpOptions, HandlePanic } from "utils/decorators/http";
import { handleRequest } from "utils/tool";

class CampaignApi {
  @GET({
    url: "/api/creator_tw_api/twitter/campaigns"
  })
  async getCampaigns(opt?: any) {
    const res = await http(opt);
    return Promise.resolve(res.data);
  }

  @PUT({
    url: "/api/creator_tw_api/twitter/campaigns"
  })
  async putCampaigns<T = any>(
    params?: any,
    option?: IHtttpOptions,
    handle?: HandlePanic<T>
  ) {
    return await handleRequest<T>(params, handle);
  }
}

export const campaignApi = new CampaignApi();
